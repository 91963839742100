<template>
  <standard-page v-if="step.name" :title="step.name">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
      <span v-if="registry">
        <router-link :title="registry.name" :to="{ name: 'processdefinitions'}">Registry: {{registry.name | truncate(10, '.....')}}</router-link>
      </span>
      <span v-if="processDefinition">
        <router-link :title="processDefinition.name" :to="{ name: 'processdefinitions'}">Process: {{processDefinition.name | truncate(10, '.....')}}</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'process-definition-steps'}">Steps</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'view-process-definition-step', params: {stepid: stepid}}">Step: {{step.name | truncate(10, '.....')}}</router-link>
      </span>
      <span>Add Attestor</span>
    </template>
    <template v-slot:content>
      <div class="az-content-label mg-b-5">Add Attestor</div>
      <div class="mg-b-20" v-html="$DEFINITIONS.provenance.addAttestor"></div>

      <div class="pd-30 pd-sm-40 bg-gray-200">
        <div class="row row-xs align-items-center mg-b-20" v-for="(row, index) in process_definition_step.attestors">
          <div class="col-md-2">
            <label class="form-label mg-b-0">Attestor</label>
          </div>
          <div class="col-md-3 mg-t-5 mg-md-t-0">
            <multiselect :options="catalogs" :selectLabel="''" @input="chooseCatalog(index)" class="form-control rounded" data-vv-as="id" id="catalog_id" label="Catalog" name="catalog_id" open-direction="bottom" placeholder="Select Catalog" v-model="row.catalog" v-validate="'required'">
              <template slot="singleLabel" slot-scope="props">
                {{props.option.name}}
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <h6>{{ props.option.name | capitalize }}</h6>
                  <span>{{ props.option.catalog }}</span>
                </div>
              </template>
            </multiselect>
          </div>
          <div class="col-md-3 mg-t-5 mg-md-t-0">
            <multiselect :options="dids" :selectLabel="''" @input="chooseDid(index)" class="form-control rounded" data-vv-as="id" id="_did" label="DID" name="did" open-direction="bottom" placeholder="Select DID" v-model="row.did" v-validate="'required'">
              <template v-if="props.option.did" slot="singleLabel" slot-scope="props">
                {{props.option.did | truncate(15, '....')}}
              </template>
              <template v-if="props.option.did" slot="option" slot-scope="props">
                <div class="option__desc">
                  <h6>{{props.option.short_name}}</h6>
                  <span>{{ props.option.did | truncate(28, "...." )}}</span>
                </div>
              </template>
            </multiselect>
          </div>
          <div class="col-md-2 mg-t-5 mg-md-t-0">
            <input class="form-control rounded" placeholder="Enter name" style="height: 42px" type="text" v-model="row.short_name">
          </div>
            <div class="col-md mg-t-10 mg-md-t-0" v-if="process_definition_step.attestors.length > 1">
                <a href="javascript:void(0)" @click="removeRow" class="btn btn-block btn-danger rounded"><i class="fa fa-trash-alt"></i></a>
            </div>
            <div class="col-md mg-t-10 mg-md-t-0">
                <a href="javascript:void(0)" v-if="index === process_definition_step.attestors.length-1" @click="addRow" class="btn btn-block btn-success rounded" type="button"><i class="fa fa-plus-square"></i></a>
            </div>
        </div>

        <button @click="addAttestor" class="btn btn-az-primary pd-x-30 mg-r-5 rounded">Submit</button>
        <button class="btn btn-secondary pd-x-30 rounded">Reset</button>
      </div>
    </template>
  </standard-page>
</template>

<script>
import EventBus from "@/event-bus";
import Multiselect from 'vue-multiselect';

export default {
  name: "AddAttestor",
  props: ['registryid', 'processdefinitionid', 'stepid'],
  components: { Multiselect },
  data() {
    return {
      registry: null,
      processDefinition: null,
      catalogs: [],
      dids: [],
      step: {
        name: null,
        did: null
      },
      process_definition_step: {
        name: null,
        desc: null,
        attestors: [{
          short_name: null,
          did: null
        }]
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getRegistry();
      await this.getProcessDefinition();
      await this.getCatalogs();
      await this.getProcessDefinitionStep();
    },
    async getRegistry() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}`);
        this.registry = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Registry", "Error");
      } finally {

      }
    },
    async getProcessDefinition() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}`);
        this.processDefinition = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Process Definition", "Error");
      } finally {

      }
    },
    async getCatalogs() {
      try {
        let reply = await this.$identity.get('identity/catalogs', { params: { page: 0, per_page: 30 } });
        this.catalogs = reply.data ? reply.data.catalogs : [];
      } catch (e) {
        this.$toastr.e("Error retrieving Catalogs", "Error");
      }
    },
    chooseCatalog(i) {
      this.dids = [];
      this.process_definition_step.attestors[i].short_name = null;
      this.process_definition_step.attestors[i].did = null;
      this.getDids(i);
    },
    chooseDid(i) {
      this.process_definition_step.attestors[i].short_name = this.process_definition_step.attestors[i].did ? this.process_definition_step.attestors[i].did.short_name : null;
    },
    async getDids(i) {
      try {
        let reply = await this.$identity.get(`/identity/catalogs/${this.process_definition_step.attestors[i].catalog.catalog}/dids`, {
          params: {
            page: 0,
            per_page: 30
          }
        });
        this.dids = reply.data ? reply.data.dids : [];

      } catch (e) {
        this.$toastr.e("Error retrieving DIDs", "Error");
      }
    },
    async getProcessDefinitionStep() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${this.stepid}`);
        this.step = data;
      } catch (e) {

      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    addRow() {
      this.process_definition_step.attestors.push({
        short_name: null,
        did: null
      });
    },
    removeRow(index) {
      this.process_definition_step.attestors.splice(index, 1);
    },
    async addAttestor() {
      let _attestors = [];
      this.process_definition_step.attestors.forEach(a => {
        if (a.did !== null) {
          a.did = a.did.did;
          _attestors.push(a);
        }
      });
      if (_attestors.length > 0) {
        try {
          this.process_definition_step.name = this.step.name;
          EventBus.$emit('openLoader');
          await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${this.stepid}`, {
            name: this.step.name,
            add_attestors: _attestors
          });
          this.$toastr.s("Attestor successfully added!", 'Success');
          this.reset();
          await this.$router.push({ name: 'view-process-definition-step' });
        } catch (e) {
          this.$toastr.e("Adding attestor failed", 'Error');
        } finally {
          this.process_definition_step.name = null;
          EventBus.$emit('closeLoader');
        }
      } else {
        this.$toastr.e("Please provide at lease one did", 'Error');
      }
    },
    reset() {
      this.process_definition_step.attestors = [{
        short_name: null,
        did: null
      }];
    }
  }
}
</script>

<style scoped>
</style>